import React, { useCallback, useEffect, useState } from "react";
import {
  Layout,
  Button,
  Dropdown,
  Space,
  Grid,
  Menu,
  Tooltip,
  notification,
} from "antd";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import {
  GlobalOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { BaseNavbar } from "./BaseNavbar";
import { Link, useNavigate } from "react-router-dom";
import { LogoutIcon } from "../assets/icons/LogoutIcon";
import { UsersIcon } from "../assets/icons/UsersIcon";
import logo from "../assets/icons/logo_evolve-esg.svg";
import userApi from "../api/user";
import { Roles, ServiceType } from "../constants/CommonEnum";

import "./layout.css";
import { log } from "../helpers/logger";

const { Content, Footer, Header, Sider } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const { xs, sm, lg } = useBreakpoint();
  const [navItems, setNavItems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [services, setServices] = useState([]);
  const [templates, setTemplates] = useState([]);
  const {
    keycloak: { token, logout },
  } = useKeycloak();
  const { sub, name, realm_access } = decodeJwt(token);
  const { t, i18n } = useTranslation("common", { keyPrefix: "common" });
  const { t: t2 } = useTranslation("common", { keyPrefix: "menu" });

  const langPickerItems = [
    {
      key: "en",
      label: t("languages.english"),
    },
    {
      key: "es",
      label: t("languages.spanish"),
    },
  ];

  const userMenuItems = [
    {
      icon: <SettingOutlined style={{ fontSize: 20 }} />,
      key: "password-change",
      label: t("passwordChange"),
    },
    {
      icon: <LogoutOutlined style={{ fontSize: 20 }} />,
      key: "logout",
      label: t("logout"),
    },
  ];

  const actions = {
    "password-change": () => navigate(`/profile/password-change`),
    logout: () => logout(),
  };

  useEffect(() => {
    handleLanguageChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLanguageChanged = useCallback(async () => {
    const securedNavbar = filterSecureNav(BaseNavbar);
    const securedNavbarWithReport = await loadDynamicMenuItem(securedNavbar);
    setNavItems(securedNavbarWithReport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);
    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLanguageChanged]);

  useEffect(() => {
    if (xs || (sm && !lg)) setCollapsed(true);
    else setCollapsed(false);
  }, [xs, sm, lg]);

  const handleMenuClick = ({ key }) => {
    if (actions[key]) actions[key]();
  };

  const loadDynamicMenuItem = async (securedNavbar) => {
    let auxTemplates = templates;
    let auxServices = services;
    if (!auxTemplates.length) {
      try {
        const { data: menu } =
          await userApi.getServicesAndTemplatesForMenu(sub);

        setTemplates(menu?.esrsTemplates);
        setServices(menu?.services);

        auxTemplates = menu?.esrsTemplates || [];
        auxServices = menu?.services || [];
      } catch (error) {
        log(error);
        notification.error({
          duration: 3,
          message: t("notification.errorGettingRegisters"),
        });
      }
    }

    const userRoles = realm_access.roles;
    const baseNavbarWithReports = securedNavbar.map((item) => {
      if (
        item.key === "report" &&
        auxServices?.some((serv) => serv === ServiceType.ESRS)
      )
        return {
          ...item,
          children: auxTemplates.map((template) => {
            if (template.type === "einf")
              return {
                key: template.id,
                label: (
                  <Link
                    to={
                      userRoles.includes(Roles.ADMIN_CROWE) ||
                      userRoles.includes(Roles.SUPERADMIN)
                        ? `/einf`
                        : `/my-einf`
                    }
                  >
                    {template.name[i18n.language?.substring(0, 2) || "es"]}
                  </Link>
                ),
              };

            if (template.type === "esrs")
              return {
                key: template.id,
                label: (
                  <Link
                    to={
                      userRoles.includes(Roles.ADMIN_CROWE) ||
                      userRoles.includes(Roles.SUPERADMIN)
                        ? `/sr/${template.id}`
                        : `/my-sr/${template.id}`
                    }
                  >
                    {
                      template.name[
                        template.langCodes?.find(
                          (l) => l === i18n.language.substring(0, 2),
                        ) || template.langCodes?.[0]
                      ]
                    }
                  </Link>
                ),
              };
          }),
        };

      if (
        item.key === "report" &&
        !auxServices?.some((serv) => serv === ServiceType.ESRS)
      )
        return; //si no tiene el servicio contratado no se muestra en el menu

      if (
        ["DM_REPORT_SCREEN", "MY_DM_REPORT_SCREEN"].includes(item.key) &&
        !auxServices?.some((serv) => serv === ServiceType.DM)
      )
        return; //si no tiene el servicio contratado no se muestra en el menu
      else return item;
    });

    return baseNavbarWithReports;
  };

  const filterSecureNav = (items) => {
    const filteredItems = [];
    if (items)
      for (const item of items) {
        const newItem = { ...item };

        if (
          item.roles &&
          !item.roles.some((role) => realm_access.roles.includes(role))
        )
          continue;
        if (!item.children) {
          if (typeof item.label === "string") newItem["label"] = t2(item.label);
          else
            newItem["label"] = React.cloneElement(
              item.label,
              [],
              t2(item.label?.props?.children),
            );
          filteredItems.push(newItem);
        } else {
          if (typeof item.label === "string") newItem["label"] = t2(item.label);
          else
            newItem["label"] = React.cloneElement(
              item.label,
              [],
              t2(item.label?.props?.children),
            );
          const filteredChildren = filterSecureNav(item.children);
          filteredItems.push({ ...newItem, children: filteredChildren });
        }
      }
    return filteredItems;
  };

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          textAlign: "center",
          paddingTop: "20px",
          borderRight: "6px solid #264491",
          overflow: "auto",
          top: "0",
          height: "100vh",
          position: "fixed",
          zIndex: 1000,
        }}
        theme="light"
        width={220}
        trigger={
          <div
            style={{
              display: "block",
              backgroundColor: "white",
              borderRight: "6px solid #264491",
              fontSize: "20px",
            }}
          >
            {collapsed ? <RightOutlined /> : <LeftOutlined />}
          </div>
        }
      >
        <Link to="/">
          <img
            src={logo}
            style={{ marginBottom: "50px", padding: "16px 8px" }}
            alt="logo"
          />
        </Link>
        <Menu selectedKeys={[]} items={navItems} mode="vertical" />
        <Tooltip title={t("logout")}>
          <Button
            onClick={() => logout()}
            type="primary"
            style={{
              position: "absolute",
              left: "10px",
              right: "10px",
              bottom: "50px",
            }}
          >
            <LogoutIcon
              style={{
                width: "14px",
                fill: "white",
                ...(!collapsed && { marginLeft: "14px" }),
              }}
            />
            {!collapsed && t("logout")}
          </Button>
        </Tooltip>
      </Sider>
      <Layout style={{ minHeight: "100vh", overflowY: "auto" }}>
        <Header
          style={{
            backgroundColor: "white",
            position: "fixed",
            zIndex: "900",
            top: "0",
            marginLeft: `${collapsed ? "80" : "220"}px`,
            width: `calc(100% - ${collapsed ? "80" : "220"}px)`,
          }}
        >
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              alignContent: "center",
              padding: "0 20px",
            }}
          >
            <Dropdown
              menu={{
                items: langPickerItems,
                styles: { backgroundColor: "white" },
                selectable: true,
                defaultSelectedKeys: i18n.language.substring(0, 2),
                onSelect: ({ key }) => {
                  i18n.changeLanguage(key);
                },
              }}
            >
              <Button
                type="link"
                style={{ marginTop: "16px" }}
                icon={
                  <GlobalOutlined
                    style={{ fontSize: "24px", color: "#264491" }}
                  />
                }
              />
            </Dropdown>
            <Dropdown
              menu={{
                items: userMenuItems,
                styles: { backgroundColor: "white" },
                onClick: handleMenuClick,
              }}
            >
              <Button className="username" type="link">
                <UsersIcon color="white" style={{ marginRight: "5px" }} />{" "}
                {name}
              </Button>
            </Dropdown>
          </Space>
        </Header>
        <Content
          style={{
            marginLeft: `${collapsed ? "80" : "220"}px`,
            width: `calc(100% - ${collapsed ? "80" : "220"}px)`,
            minHeight: "calc(100vh - 100px)",
            marginTop: "80px",
            padding: "0px 30px",
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            marginLeft: `${collapsed ? "80" : "220"}px`,
            width: `calc(100% - ${collapsed ? "80" : "220"}px)`,
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          Evolve ESG© {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};
