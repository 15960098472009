import api from "./api";
const prefix = "user";

const userApi = {
  getAllUsers: function () {
    return api.get(`${prefix}`);
  },
  getUser: function (id) {
    return api.get(`${prefix}/${id}`);
  },
  findOneByKeycloakId: function (keycloakId) {
    return api.get(`${prefix}/find-by/keycloak/${keycloakId}`);
  },
  findByOrganization: function (id) {
    return api.get(`${prefix}/find-by/organization/${id}`);
  },
  createUser: function (user) {
    return api.post(`${prefix}`, user);
  },
  editUser: function (id, user) {
    return api.put(`${prefix}/${id}`, user);
  },
  deleteUser: function (id) {
    return api.delete(`${prefix}/${id}`);
  },
  getPasswordPolicies: function () {
    return api.get(`${prefix}/password-policies`);
  },
  resetPassword: function (id) {
    return api.put(`${prefix}/${id}/reset-password`);
  },
  changePassword: function (id, data) {
    return api.put(`${prefix}/${id}/change-password`, data);
  },
  createPermission: function (permission) {
    return api.post(`${prefix}/permission`, permission);
  },
  editPermission: function (id, permissionData) {
    return api.put(`${prefix}/permission/${id}`, permissionData);
  },
  deletePermission: function (id) {
    return api.delete(`${prefix}/permission/${id}`);
  },
  getPermissionByUser: function (userId) {
    return api.get(`${prefix}/permission/user/${userId}`);
  },
  getPermissionToCompleteReport: function (keycloakId) {
    return api.get(`${prefix}/permission-report/${keycloakId}`);
  },
  getServicesAndTemplatesForMenu: function (keycloakId) {
    return api.get(`${prefix}/menu/${keycloakId}`);
  },
  releaseDisabledUser: function (id) {
    return api.delete(`${prefix}/${id}/disable`);
  },
};

export default userApi;
